<template>
  <b-row>
    <b-col>
      <confirmation ref="Confirmation"></confirmation>
      <b-card>
        <b-row style="margin-bottom: 15px">
          <b-col sm="12" md="6">
            <h6>{{ title }}</h6>
          </b-col>
          <b-col sm="12" md="6">
            <div class="loginbtnbg">
              <b-button class="login-blue" @click="addNewPhoneInfo()">
                <span class="label">Add New</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="separator mb-sm-2"></div>
          </b-col>
        </b-row>
        <div
          v-for="phone in contactPhone"
          :key="phone.companyContactPhoneID"
          style="margin-bottom: 10px"
        >
          <!--ReadOnly-->
          <b-row v-if="editIndex != phone.companyContactPhoneID" align-v="start">
            <b-col lg="3" class="form-group form-inline">
              {{ phone.phoneNumber | VMask(phoneMask) }}
            </b-col>
            <b-col lg="3" class="form-group form-inline">
              <div v-show="phone.phoneNumberExtension">Ext.{{ phone.phoneNumberExtension }}</div>
            </b-col>
            <b-col lg="3" class="form-group form-inline">
              &nbsp;&nbsp;{{
                phone.phoneNumberType ? loadPhoneDescription(phone.phoneNumberType) : ""
              }}
            </b-col>
            <b-col lg="2">
              <div class="table-action-buttons-container">
                <custom
                  @edit="startEditing(phone.companyContactPhoneID)"
                  @remove="deleteContactPhone(phone.companyContactPhoneID, phone.phoneNumber)"
                />
              </div>
            </b-col>
          </b-row>
          <!--Edit -->
          <b-row v-if="editIndex == phone.companyContactPhoneID" align-v="start">
            <b-col lg="3">
              <input
                type="tel"
                :id="'company-phone-' + phone.companyContactPhoneID"
                @keydown.esc="cancelEditing"
                class="form-control"
                :class="{ 'is-invalid': $v.editphoneInfo.$error }"
                v-model="editphoneInfo.phoneNumber"
                @input.self="$v.editphoneInfo.$touch"
                v-mask="phoneMask"
                placeholder="Phone Number"
              />
              <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumber.required">
                Field is required
              </div>
              <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumber.minLength">
                Must have at least 10 digits.
              </div>
            </b-col>
            <b-col lg="3" class="form-group form-inline">
              <input
                type="text"
                placeholder="Ext."
                :id="'company-ext-' + phone.companyContactPhoneID"
                class="form-control col-sm-9"
                :class="{ 'is-invalid': $v.editphoneInfo.phoneNumberExtension.$error }"
                @keydown.esc="cancelEditing"
                v-model="editphoneInfo.phoneNumberExtension"
                v-mask="extMask"
              />
              <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumberExtension.maxLength">
                Must have at most
                {{ $v.editphoneInfo.phoneNumberExtension.$params.maxLength.max }} digits.
              </div>
            </b-col>
            <b-col lg="3">
              <b-form-select
                v-model="editphoneInfo.phoneNumberType"
                value-field="phoneNumberTypeID"
                text-field="phoneNumberTypeDesc"
                :options="phoneTypes"
                :class="{ 'is-invalid': !$v.editphoneInfo.phoneNumberType.required }"
                plain
              ></b-form-select>
              <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumberType.required">
                Field is required
              </div>
            </b-col>
            <b-col lg="3">
              <div class="table-action-buttons-container" style="margin-top: 10px">
                <b-button @click="savePhone()" size="sm" variant="success" class="rounded-style">
                  Save
                </b-button>

                <b-button
                  @click="cancelEditing()"
                  size="sm"
                  variant="outline-danger"
                  class="rounded-style space-from-left"
                >
                  Cancel
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Add -->
        <b-row v-show="addMode" class="mt-3 pt-3 border-top" align-v="start">
          <b-col lg="3">
            <input
              type="tel"
              id="add-company-phone"
              @keydown.esc="cancelEditing"
              class="form-control"
              :class="{ 'is-invalid': $v.editphoneInfo.phoneNumber.$error }"
              v-model.number="editphoneInfo.phoneNumber"
              @input="$v.editphoneInfo.$touch"
              v-mask="phoneMask"
              placeholder="Phone Number"
            />
            <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumber.required">
              Field is required
            </div>
            <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumber.minLength">
              Must have at least {{ $v.editphoneInfo.phoneNumber.$params.minLength.min }} digits.
            </div>
          </b-col>
          <b-col lg="3" class="form-group form-inline">
            <input
              type="text"
              placeholder="Ext."
              id="add-company-ext"
              class="form-control col-sm-9"
              :class="{ 'is-invalid': $v.editphoneInfo.phoneNumberExtension.$error }"
              @keydown.esc="cancelEditing"
              v-model="editphoneInfo.phoneNumberExtension"
              v-mask="extMask"
            />
            <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumberExtension.maxLength">
              Must have at most
              {{ $v.editphoneInfo.phoneNumberExtension.$params.maxLength.max }} digits.
            </div>
          </b-col>
          <b-col lg="3">
            <b-form-select
              v-model="editphoneInfo.phoneNumberType"
              value-field="phoneNumberTypeID"
              text-field="phoneNumberTypeDesc"
              :options="phoneTypes"
              :class="{ 'is-invalid': !$v.editphoneInfo.phoneNumberType.required }"
              plain
            ></b-form-select>
            <div class="text-danger" v-if="!$v.editphoneInfo.phoneNumberType.required">
              Field is required
            </div>
          </b-col>
          <b-col lg="3">
            <div class="table-action-buttons-container">
              <b-button @click="savePhone()" size="sm" variant="success" class="rounded-style">
                Save
              </b-button>

              <b-button
                @click="cancelEditing()"
                size="sm"
                variant="outline-danger"
                class="rounded-style space-from-left"
              >
                Cancel
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import confirmation from "../../../components/Common/Confirmation.vue";
import CompanyContact from "../../../mixins/CompanyContactMixin.vue";
import { validationMixin } from "vuelidate";
import PhoneMixin from "../../../mixins/PhoneMixin.vue";
import ButtonActions from "../../../components/Common/ButtonActions.vue";

const { required, maxLength, minLength } = require("vuelidate/lib/validators");
export default {
  components: {
    Confirmation: confirmation,
    custom: ButtonActions,
  },
  props: {
    contactID: {
      type: Number,
      default: 0,
    },
    onContactIdChange: {
      type: Function,
    },
    companyContactdetail: {
      type: Object,
    },
    validation: {
      type: Boolean,
    },
    from: {
      type: String,
    },
  },
  mixins: [CompanyContact, PhoneMixin, validationMixin],
  validations: {
    editphoneInfo: {
      phoneNumber: {
        required,
        minLength: minLength(14),
      },
      phoneNumberType: {
        required,
      },
      phoneNumberExtension: {
        maxLength: maxLength(6),
      },
    },
  },
  data() {
    return {
      title: "Phone",
      contactPhoneID: 0,
      editphoneInfo: {
        contactID: this.contactID,
        phoneNumber: "",
        phoneNumberExtension: null,
        phoneNumberType: "",
      },
      addMode: false,
      editIndex: -1,
    };
  },
  async created() {
    await this.refreshPhoneData();
  },
  methods: {
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    cancelEditing() {
      this.resetPhoneData();
    },
    resetPhoneData() {
      this.contactPhoneID = 0;
      this.addMode = false;
      this.editIndex = -1;
    },
    startEditing(contactPhoneID) {
      this.contactPhoneID = contactPhoneID;
      this.editphoneInfo = _.find(this.contactPhone, ["companyContactPhoneID", contactPhoneID]);
      this.addMode = false;
      this.editIndex = contactPhoneID;
    },
    addNewPhoneInfo() {
      this.editphoneInfo = {
        contactID: this.contactID,
        phoneNumber: "",
        phoneNumberExtension: null,
        phoneNumberType: "",
      };
      this.addMode = true;
      this.contactPhoneID = 0;
    },
    async savePhone() {
      this.$v.$touch();
      if (this.$v.editphoneInfo.$error || this.validation) {
        this.setModalDangerMessage(
          "Invalid entry. Make sure the form has been filled out correctly."
        );
      } else {
        if (!this.contactID && this.from !== "application-company-contacts") {
          await this.createContactWithPhone();
        } else {
          this.addMode ? await this.addContactPhone() : await this.updateContactPhone();
        }
      }
    },
    async updateContactPhone() {
      await this.updateCompanyContactPhone(this.editphoneInfo)
        .then(() => {
          this.resetPhoneData();
          this.refreshPhoneData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async addContactPhone() {
      await this.addCompanyContactPhone(this.editphoneInfo)
        .then(() => {
          this.resetPhoneData();
          this.refreshPhoneData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createContactWithPhone() {
      let contactID = await this.addCompanyContact(this.companyContactdetail);
      if (contactID) {
        this.$emit("onContactIdChange", contactID);
        await this.addCompanyContactPhone({ ...this.editphoneInfo, contactID })
          .then(() => {
            this.resetPhoneData();
            this.refreshPhoneData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async deleteContactPhone(companyPhoneID, phoneNumber) {
      this.$refs.Confirmation.show({
        title: "Delete Phone Number",
        message: `Are you sure you want to delete ${this.formatPhoneNumber(phoneNumber)}?`,
        okButton: "Delete",
      }).then(async (result) => {
        if (result) {
          await this.deleteCompanyContactPhone(companyPhoneID).then(() => {
            this.resetPhoneData();
            this.refreshPhoneData();
          });
        }
      });
    },
    async refreshPhoneData() {
      await this.getCompanyContactPhoneByContactId(this.contactID);
      await this.getPhoneTypes();
    },
    loadPhoneDescription(phoneTypeID) {
      return (
        this.phoneTypes &&
        this.phoneTypes.length &&
        this.phoneTypes.find((pt) => pt.phoneNumberTypeID === phoneTypeID).phoneNumberTypeDesc
      );
    },
  },
};
</script>

<style>
.form-control.is-invalid {
  border-color: #dc3545 !important;
}
.space-from-left {
  margin-left: 0.5rem;
}
</style>
