<template>
  <section class="full-width">
    <confirmation ref="Confirmation"></confirmation>

    <div class="manage-contacts-grid-header mb-3">
      <h1 class="bold-header-text app-header">{{ title }}</h1>
      <button
        class="base-button"
        @click="addNewContact"
        :disabled="disableField"
        v-if="from !== 'application-company-contacts'"
      >
        Add New
      </button>
    </div>
    <p
      class="base-header-text"
      style="margin-top: 1.4rem"
      v-if="from === 'application-company-contacts'"
    >
      Please identify all contacts linked to this application. Additional contacts can be added or
      modified from on the <strong>Manage Contacts</strong> page.
    </p>
    <p class="base-header-text" style="margin-top: 1.4rem" v-else>
      All contacts associated with your companies must be included on an application. Click the
      <strong>Add New</strong> button above to get started.
    </p>
    <b-row v-if="from === 'application-company-contacts'" align-h="end">
      <b-col sm="12" md="6">
        <div class="new-contacts-section" v-if="from === 'application-company-contacts'">
          <b-form-group label="Select contact" style="width: 100%">
            <b-form-select
              class="form-select"
              v-model="applicationAdditionContactID"
              aria-placeholder="Select contact"
              :options="applicationAdditionContacts"
              value-field="value"
              text-field="text"
              :disabled="disableField || !applicationAdditionContacts.length"
            ></b-form-select>
            <hint class="hint-content" v-if="!applicationAdditionContacts.length">
              No additional contacts available</hint
            >
          </b-form-group>
          <div class="loginbtnbg">
            <b-button
              class="login-blue"
              style="margin-left: 20px"
              @click="addNewContactApplication"
              :disabled="disableField || !applicationAdditionContactID || processing"
            >
              <span class="label">Add</span>
            </b-button>
          </div>
        </div></b-col
      >
    </b-row>
    <!-- Note - Our Contact Page does not have the Add New Option as per logic, so we don't use this code -->
    <!-- <b-alert :show="from === 'application-company-contacts'">
      <div>
        <b>Accreditation/Certification Coordinator, Billing Contact and Organizational Leader</b>
        contact types are required. You can enter a contact type one time and use the multi-select
        dropdown to identify all associated contact types.
      </div>
    </b-alert> -->
    <b-card class="dashboard-card">
      <!--         id="grid1"
        ref="grid"
        :selectable="true"
        :resizable="true" =-->
      <kendo-grid
        :sortable="true"
        :sort="sort"
        :data-items="gridData"
        :columns="tableColumns"
        @datastatechange="dataStateChange"
        @rowclick="onRowClick"
      >
        <template v-slot:notesTemplate="{ props }">
          <td class="text-center">
            <div v-if="props.dataItem.notes">
              <img
                width="25"
                :id="props.dataItem.notes"
                src="../../../assets/img/app/sidebar-app-ico-blue-01.svg"
              />
              <b-tooltip
                :target="props.dataItem.notes"
                placement="topleft"
                :title="props.dataItem.notes"
              ></b-tooltip>
            </div>
          </td>
        </template>
        <template v-slot:phoneNumbersTemplate="{ props }">
          <td class="text-center">
            <div v-if="props.dataItem.phoneNumbers">
              <div v-for="number in props.dataItem.phoneNumbers.split(',')" :key="number">
                {{ number | VMask(phoneMask) }}
              </div>
            </div>
          </td>
        </template>
        <template v-slot:contactTypeDescriptionsTemplete="{ props }">
          <td class="text-center">
            <div v-if="props.dataItem.contactTypeDescriptions">
              <ReadMoreList
                separator=","
                :linesToShow="1"
                :listText="props.dataItem.contactTypeDescriptions"
              />
            </div>
          </td>
        </template>
        <template v-slot:myTemplate="{ props }">
          <td class="k-command-cell">
            <custom
              :data-item="props.dataItem"
              @edit="editRecord"
              @remove="deleteRecord"
              :from="from"
              :disableField="disableField"
            />
          </td>
        </template>
      </kendo-grid>
      <CompanyContactsModal
        ref="CompanyContactsModal"
        :applicationCompanyID="applicationCompanyID"
        :from="from"
      ></CompanyContactsModal>
    </b-card>
  </section>
</template>
<script>
import { process } from "@progress/kendo-data-query";
import { Grid } from "@progress/kendo-vue-grid";
import CompanyMixin from "../../../mixins/CompanyMixin.vue";
import CompanyContactMixin from "../../../mixins/CompanyContactMixin.vue";
import ApplicationCompanyContactMixin from "../../../mixins/ApplicationCompanyContactMixin.vue";
import { mapGetters } from "vuex";
import ReadMoreList from "../../../components/Common/ReadMoreList.vue";
import ButtonActions from "../../../components/Common/ButtonActions.vue";
import CompanyContactsModal from "../../../components/Modals/CompanyContactsModal.component.vue";
import Confirmation from "../../../components/Common/Confirmation.vue";
import { getApplicationCompanyId, getCurrentCompany } from "../../../utils/customFunctions";
import { bus } from "../../../main";
import { Hint } from "@progress/kendo-vue-labels";

export default {
  props: [
    "title",
    "currentCompanyID",
    "showCompanyId",
    "addNewRecordTitle",
    "editRecordTitle",
    "deleteRecordTitle",
    "tableColumns",
    "from",
  ],
  components: {
    "kendo-grid": Grid,
    ReadMoreList,
    custom: ButtonActions,
    CompanyContactsModal,
    Confirmation,
    hint: Hint,
  },
  mixins: [CompanyMixin, CompanyContactMixin, ApplicationCompanyContactMixin],

  data() {
    return {
      phoneMask: "(###) ###-####",
      gridData: [],
      sort: [],
      disableField: false,
      programsOfInterestContactTypes: [],
      applicationCompanyID: null,
      applicationAdditionContacts: [],
      applicationAdditionContactID: "",
    };
  },
  async created() {
    if (this.from === "manage-contacts") {
      bus.$emit("showFullpageOverlay", {
        showLayover: true,
      });
    }
    this.applicationCompanyID = getApplicationCompanyId();
    if (this.from !== "application-company-contacts") {
      await this.getTableData();
    }

    if (this.from === "application-company-contacts") {
      this.getApplicationAdditionalContactList();
    }
    bus.$on("contactTypeOptions", async (data) => {
      //only programs of interest contact options
      this.programsOfInterestContactTypes = data.filter(({ label }) => label !== "General");
    });
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },

  mounted() {
    bus.$on("applicationStatus", async (data) => {
      this.disableField = data;
      if (this.from === "application-company-contacts") {
        if (data) {
          await this.getApplicationSubmitCompanyContactsTable(this.applicationCompanyID);
          this.getData(this.contacts);
        } else {
          await this.getTableData();
        }
      }
    });
  },
  beforeDestroy() {
    bus.$off("contactTypeOptions");
    bus.$off("applicationStatus");
  },
  methods: {
    getData(data) {
      this.gridData = process(data, {
        sort: this.sort,
      });
    },
    async getTableData() {
      switch (this.from) {
        case "application-company-contacts":
          await this.getApplicationCompanyContactsTable(this.applicationCompanyID);
          break;
        case "manage-contacts":
          await this.getContactsAllByCompanyId(this.companyID);
          break;
        default:
          await this.getCompanyContactsTable(this.companyID);
          break;
      }
      this.getData(this.contacts);
    },
    async createAppState(dataState) {
      this.getTableData();
      this.sort = dataState.sort;
    },
    async dataStateChange(event) {
      await this.createAppState(event.data);
    },
    onRowClick() {},
    addNewContact() {
      this.$refs.CompanyContactsModal.show({
        showCompanyId: this.showCompanyId,
        title: this.addNewRecordTitle,
        companyID: this.companyID,
        exitFunction: async () => {
          this.getTableData();
        },
        assignedContactTypes: this.getAssignedContactTypes(),
      });
    },
    editRecord(dataItem) {
      let assignedContactTypes = [];
      //Already assigned contact types except current record
      this.contacts
        .filter(({ contactID }) => contactID !== dataItem.contactID)
        .forEach(
          ({ contactTypeDescriptions }) =>
            (assignedContactTypes = assignedContactTypes.concat(contactTypeDescriptions.split(",")))
        );
      this.$refs.CompanyContactsModal.show({
        showCompanyId: this.showCompanyId,
        title: `${this.editRecordTitle}${dataItem.contactID} `,
        contactID: dataItem.contactID,
        companyID: this.showCompanyId ? dataItem.companyID : this.companyID,
        exitFunction: async () => {
          this.getTableData();
        },
        assignedContactTypes,
      });
    },
    deleteRecord(dataItem) {
      this.$refs.Confirmation.show({
        title: this.deleteRecordTitle,
        message: `Are you sure you want to delete ${dataItem.firstName} ${dataItem.lastName}?`,
        okButton: "Yes",
        cancelButton: "No",
        exitFunction: () => {},
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage("");
          if (this.from === "application-company-contacts")
            await this.deleteApplicationCompanyContact(
              this.applicationCompanyID,
              dataItem.contactID
            ).then(async () => {
              this.getTableData();
              if (this.from === "application-company-contacts") {
                this.getApplicationAdditionalContactList();
              }
            });
          else
            await this.deleteCompanyContact(dataItem.contactID).then(async () => {
              this.getTableData();
            });
        }
      });
    },
    isAllThreeContactsAvailable() {
      const types = [
        "Accreditation/Certification Coordinator",
        "Organizational Leader",
        "Billing Contact",
      ];
      const unavailableTypes = [];
      for (let i = 0; i < types.length; i++) {
        const isTypeAvailable = this.contacts.some((contact) => {
          return _.includes(contact.contactTypeDescriptions, types[i]);
        });
        if (!isTypeAvailable) {
          unavailableTypes.push(types[i]);
        }
      }
      if (!unavailableTypes.length) {
        return true;
      } else {
        // this.setDangerMessage(
        //   `You are missing contact type ${unavailableTypes.join(
        //     ", "
        //   )}. Each contact type must be used at least once`
        // );
        return false;
      }
    },
    areAllProgramSpecificOptionsAdded() {
      let unSelectedOptionsGroupLabel = [];
      this.programsOfInterestContactTypes.forEach(({ label, options }) => {
        const flag = options.every(({ description }) =>
          this.getAssignedContactTypes().includes(description)
        );
        !flag && unSelectedOptionsGroupLabel.push(label);
      });
      if (unSelectedOptionsGroupLabel.length) {
        // this.setDangerMessage(
        //   `You are missing contact types of the following group(s) : ${unSelectedOptionsGroupLabel.join(
        //     ", "
        //   )}. All contact types of these group must be used at least once`
        // );
        return false;
      }
      this.setInfoMessage("Application Saved Successfully.");
      return true;
    },
    getAssignedContactTypes() {
      let assignedContactTypes = [];
      //Already assigned contact types
      this.contacts.forEach(
        ({ contactTypeDescriptions }) =>
          (assignedContactTypes = assignedContactTypes.concat(contactTypeDescriptions.split(",")))
      );
      return assignedContactTypes;
    },
    addNewContactApplication() {
      if (this.applicationAdditionContactID) {
        this.addApplicationCompanyNewContact(
          this.companyID,
          this.applicationCompanyID,
          this.applicationAdditionContactID
        ).then(() => {
          if (this.statusCode === 200) {
            this.getTableData();
            this.getApplicationAdditionalContactList();
            this.applicationAdditionContactID = "";
          }
        });
      }
    },
    getApplicationAdditionalContactList() {
      this.getApplicationCompanyAdditionalContacts(this.companyID, this.applicationCompanyID);
    },
  },
  computed: {
    ...mapGetters({
      getCompanyContact: "getCompanyContact",
      getCompanyContacts: "getCompanyContacts",
      getApplicationAdditionalContacts: "getApplicationAdditionalContacts",
    }),
    contacts() {
      if (this.from === "manage-contacts") {
        return this.getCompanyContacts;
      } else {
        return this.getCompanyContact;
      }
    },
    companyID() {
      const { companyID = 0 } = getCurrentCompany();
      if (this.from === "manage-contacts") {
        return companyID;
      } else {
        return this.$route.params.companyID;
      }
    },
  },
  watch: {
    getApplicationAdditionalContacts(newData) {
      const contacts = newData.map((contact, i) => {
        return {
          ...contact,
          displayOrder: i + 1,
          text: contact.firstName + " " + contact.lastName,
          value: contact.contactID,
        };
      });
      this.applicationAdditionContacts = contacts;
    },
  },
};
</script>
<style scoped>
.full-width {
  width: 100%;
}
.new-contacts-section {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}
</style>
