<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import { instance } from "../../src/request/app-axios.js";

export default {
  data() {
    return {
      company: {},
      companyContacts: [],
      companies: [],
      companyContact: {},
      contactPhone: [],
      salutation: [],
      contactTypeOptions: [],
      statusCode: null,
      companyContactData: [],
      processing: false,
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "setModalDangerMessage",
      "setModalAlertMessage",
      "setModalInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "changeCompanyContact",
    ]),
    async getCompanyContactByContactID(contactID) {
      await axios
        .get(`${WebAPIUrl}/api/company-contact/${contactID}`)
        .then((result) => {
          this.companyContact = result.data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async updateCompanyContact(contact) {
      this.processing = true;
      contact.lastModifiedByID = localStorage.getItem("employeeID");
      return await axios
        .put(`${WebAPIUrl}/api/company-contact`, contact)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage(
            `Successfully updated Contact ${contact.firstName} ${contact.lastName}`
          );
          this.processing = false;
        })
        .catch((error) => {
          this.statusCode = error.response.status;
          this.setDangerMessage(error.response.data);
          this.processing = false;
        });
    },

    async addCompanyContact(contact) {
      this.processing = true;
      return await instance
        .post(`${WebAPIUrl}/api/company-contact`, contact)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage(`Successfully Added ${contact.firstName} ${contact.lastName}`);
          this.processing = false;
          return result.data;
        })
        .catch((error) => {
          this.statusCode = error.response.status;
          this.setModalDangerMessage(error.response.data);
          this.processing = false;
        });
    },
    async deleteCompanyContact(contactID) {
      await axios
        .delete(`${WebAPIUrl}/api/company-contact/${contactID}`)
        .then((result) => {
          this.statusCode = result.status;
          this.appendInfoMessage(result.data);
        })
        .catch((error) => {
          this.appendDangerMessage(error);
        });
    },
    async getCompanyContactPhoneByContactId(contactID) {
      await axios
        .get(`${WebAPIUrl}/api/company-contact/phone?contactID=${contactID}`)
        .then((result) => {
          this.contactPhone = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },

    async updateCompanyContactPhone(contact) {
      var numberWithMask = contact.phoneNumber;
      contact.phoneNumber = this.removePhoneMask(contact.phoneNumber);
      await axios
        .put(`${WebAPIUrl}/api/company-contact/phone`, contact)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage(`Successfully updated phone number ${numberWithMask}`);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async addCompanyContactPhone(companyContactPhone) {
      var numberWithMask = companyContactPhone.phoneNumber;
      companyContactPhone.phoneNumber = this.removePhoneMask(companyContactPhone.phoneNumber);
      await instance
        .post(`${WebAPIUrl}/api/company-contact/phone`, companyContactPhone)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage(`Successfully added phone number ${numberWithMask}`);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async deleteCompanyContactPhone(companyContactPhoneID) {
      return await axios
        .delete(`${WebAPIUrl}/api/company-contact/phone/${companyContactPhoneID}`)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage(result.data);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    // Contact Types
    async getContactTypeOptions(params = {}) {
      const options = {
        url: `${WebAPIUrl}/api/contact-type/by-program`,
        method: "GET",
        params,
      };
      await axios(options)
        .then((result) => {
          this.contactTypeOptions = result.data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    //Company Contacts
    async getCompanyContactsTable(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-contact/table/${companyID}`)
        .then((result) => {
          const { data = [] } = result.data;
          this.changeCompanyContact(data);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
  },
};
</script>
