<template>
  <div class="even-space">
    <div v-if="from !== 'application-company-contacts'">
      <kbutton
        :icon="'pencil'"
        :theme-color="'primary'"
        :fill-mode="'outline'"
        :size="'small'"
        @click="editHandler"
      >
      </kbutton>
    </div>
    <div v-if="showDownload">
      <a :href="downloadPath || '#'" download :title="downloadTooltip || ''">
        <kbutton :icon="'download'" :theme-color="'success'" :fill-mode="'outline'" :size="'small'">
        </kbutton>
      </a>
    </div>
    <div v-if="showUpload">
      <div class="custom-upload-btn-wrapper">
        <kbutton :icon="'upload'" :theme-color="'success'" :fill-mode="'outline'" :size="'small'">
        </kbutton>
        <input
          type="file"
          name="upload"
          @change="uploadHandler"
          :accept="uploadFileTypes || ''"
          :title="uploadTooltip || ''"
        />
      </div>
    </div>
    <div>
      <kbutton
        :icon="'trash'"
        :theme-color="'error'"
        :fill-mode="'outline'"
        :size="'small'"
        @click="removeHandler"
        :disabled="disableField"
      >
      </kbutton>
    </div>
  </div>
</template>
<script>
import { Button } from "@progress/kendo-vue-buttons";

export default {
  components: {
    kbutton: Button,
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    showUpload: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showDownload: {
      type: Boolean,
      default() {
        return false;
      },
    },
    downloadTooltip: String,
    uploadFileTypes: String,
    uploadTooltip: String,
    downloadPath: String,
    from: String,
    disableField: Boolean,
  },
  methods: {
    editHandler: function () {
      this.$emit("edit", this.dataItem);
    },
    removeHandler: function () {
      this.$emit("remove", this.dataItem);
    },
    uploadHandler: function (e) {
      this.$emit("upload", e.target.files);
    },
  },
};
</script>
<style scoped>
.space-from-left {
  margin-left: 0.5rem;
}

.even-space {
  display: flex;
  justify-content: space-evenly;
}
</style>
