var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalbasic",attrs:{"id":"modalbasic","size":"lg","title":this.title,"hide-footer":true,"no-close-on-backdrop":true},on:{"hide":function($event){return _vm.close()},"ok":function($event){return _vm.close()},"cancel":function($event){return _vm.close()}},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('div',{staticClass:"modal-height-adjust"},[_c('messages'),_c('b-row',[_c('b-col',[(_vm.showCompanyId)?_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Company"}},[_c('b-form-select',{attrs:{"plain":"","state":_vm.$v.companyContact.companyID.$dirty ? !_vm.$v.companyContact.companyID.$error : null,"options":_vm.branchLocations,"value-field":"companyID","text-field":"companyLegalName"},on:{"input":_vm.$v.companyContact.companyID.$touch},model:{value:(_vm.companyContact.companyID),callback:function ($$v) {_vm.$set(_vm.companyContact, "companyID", $$v)},expression:"companyContact.companyID"}}),(!_vm.$v.companyContact.companyID.required && _vm.$v.companyContact.companyID.dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" Company is required! ")]):_vm._e()],1)],1)],1):_vm._e(),_c('b-card',{attrs:{"title":"Contact Info","icon":""}},[_c('b-form',[_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Salutation:","label-for":"nested-salutation"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-salutation","state":_vm.$v.companyContact.salutation.$dirty
                        ? !_vm.$v.companyContact.salutation.$error
                        : null,"placeholder":"Salutation"},model:{value:(_vm.$v.companyContact.salutation.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyContact.salutation, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyContact.salutation.$model"}}),(!_vm.$v.companyContact.salutation.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.salutation.$error}},[_vm._v(" Maximum Characters Allowed 4! ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Title:","label-for":"nested-title"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-title","state":_vm.$v.companyContact.title.$dirty ? !_vm.$v.companyContact.title.$error : null,"placeholder":"Title"},model:{value:(_vm.$v.companyContact.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyContact.title, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyContact.title.$model"}}),(!_vm.$v.companyContact.title.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.title.$error}},[_vm._v(" Maximum Characters Allowed 50! ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"First Name:","label-for":"nested-firstName"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-firstName","state":_vm.$v.companyContact.firstName.$dirty
                        ? !_vm.$v.companyContact.firstName.$error
                        : null,"placeholder":"First Name"},model:{value:(_vm.$v.companyContact.firstName.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyContact.firstName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyContact.firstName.$model"}}),(
                      !_vm.$v.companyContact.firstName.required && _vm.$v.companyContact.firstName.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" First Name is required! ")]):(!_vm.$v.companyContact.firstName.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.firstName.$error}},[_vm._v(" Maximum Characters Allowed 50! ")]):_vm._e()],1)],1),_c('b-col',{staticClass:"d-flex",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name:","label-for":"nested-lastName"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-lastName","state":_vm.$v.companyContact.lastName.$dirty
                        ? !_vm.$v.companyContact.lastName.$error
                        : null,"placeholder":"Last Name"},model:{value:(_vm.$v.companyContact.lastName.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyContact.lastName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyContact.lastName.$model"}}),(
                      !_vm.$v.companyContact.lastName.required && _vm.$v.companyContact.lastName.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" Last Name is required! ")]):(!_vm.$v.companyContact.lastName.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.lastName.$error}},[_vm._v(" Maximum Characters Allowed 50! ")]):_vm._e()],1),_c('b-form-group',{staticClass:"ms-2",attrs:{"label":"Suffix:","label-for":"nested-suffix"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-suffix","state":_vm.$v.companyContact.suffix.$dirty ? !_vm.$v.companyContact.suffix.$error : null,"placeholder":"Suffix"},model:{value:(_vm.$v.companyContact.suffix.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyContact.suffix, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyContact.suffix.$model"}}),(!_vm.$v.companyContact.suffix.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.suffix.$error}},[_vm._v(" Maximum Characters Allowed 50! ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Email:","label-for":"nested-emailaddress"}},[_c('b-form-input',{staticClass:"col-sm-6",attrs:{"id":"nested-emailaddress","state":_vm.$v.companyContact.emailAddress.$dirty
                        ? !_vm.$v.companyContact.emailAddress.$error
                        : null,"placeholder":"Email"},model:{value:(_vm.$v.companyContact.emailAddress.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyContact.emailAddress, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyContact.emailAddress.$model"}}),(
                      !_vm.$v.companyContact.emailAddress.required &&
                      _vm.$v.companyContact.emailAddress.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" Email is required! ")]):(!_vm.$v.companyContact.emailAddress.email)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.emailAddress.$error}},[_vm._v(" Enter a valid email address! ")]):(!_vm.$v.companyContact.emailAddress.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.emailAddress.$error}},[_vm._v(" Maximum Characters Allowed 50! ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Contact Type(s):","label-for":"nested-contacttypes"}},[_c('multiselect',{class:{ 'multiselect-is-invalid': _vm.$v.companyContact.contactTypes.$error },attrs:{"options":_vm.dynamicContactTypeOptions,"group-values":"options","group-label":"label","track-by":"id","label":"description","multiple":true,"close-on-select":false,"placeholder":"Select Contact Type(s)..."},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{attrs:{"title":props.option.tooltip}},[_vm._v(" "+_vm._s(_vm.getOptionLabel(props))+" ")])]}}]),model:{value:(_vm.companyContact.contactTypes),callback:function ($$v) {_vm.$set(_vm.companyContact, "contactTypes", $$v)},expression:"companyContact.contactTypes"}}),(
                      !_vm.$v.companyContact.contactTypes.required &&
                      _vm.$v.companyContact.contactTypes.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" Contact type is required! ")]):_vm._e()],1)],1)],1),(_vm.showOtherConsultingFirm)?_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Other Consulting Firm","label-for":"nested-contacttypes"}},[_c('b-form-input',{attrs:{"type":"text","state":_vm.$v.companyContact.otherConsultingFirm.$dirty
                        ? !_vm.$v.companyContact.otherConsultingFirm.$error
                        : null,"placeholder":"Other Consulting Firm"},on:{"input":_vm.$v.companyContact.otherConsultingFirm.$touch},model:{value:(_vm.companyContact.otherConsultingFirm),callback:function ($$v) {_vm.$set(_vm.companyContact, "otherConsultingFirm", $$v)},expression:"companyContact.otherConsultingFirm"}}),(
                      !_vm.$v.companyContact.otherConsultingFirm.required &&
                      _vm.$v.companyContact.otherConsultingFirm.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" Other Consulting Firm is required! ")]):(!_vm.$v.companyContact.otherConsultingFirm.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.otherConsultingFirm.$error &&
                      _vm.$v.companyContact.otherConsultingFirm.dirty}},[_vm._v(" Maximum Characters Allowed 300! ")]):_vm._e()],1)],1)],1):_vm._e(),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.companyContact &&
                _vm.companyContact.contactTypes &&
                _vm.companyContact.contactTypes.find(function (ct) { return ct.id === 15; }) !== undefined
              ),expression:"\n                companyContact &&\n                companyContact.contactTypes &&\n                companyContact.contactTypes.find((ct) => ct.id === 15) !== undefined\n              "}],staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Consulting Firm Name"}},[_c('b-form-select',{attrs:{"plain":"","state":_vm.$v.companyContact.affiliateID.$dirty
                        ? !_vm.$v.companyContact.affiliateID.$error
                        : null,"options":_vm.consultants},on:{"input":_vm.$v.companyContact.affiliateID.$touch},model:{value:(_vm.companyContact.affiliateID),callback:function ($$v) {_vm.$set(_vm.companyContact, "affiliateID", $$v)},expression:"companyContact.affiliateID"}}),(
                      !_vm.$v.companyContact.affiliateID.required &&
                      _vm.$v.companyContact.affiliateID.dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" Consultant is required! ")]):_vm._e()],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.companyContact.affiliateID === -1),expression:"companyContact.affiliateID === -1"}],attrs:{"label":"Other"}},[_c('b-form-input',{attrs:{"type":"text","state":_vm.$v.companyContact.consultantOther.dirty
                        ? !_vm.$v.companyContact.consultantOther.$error
                        : null},on:{"input":_vm.$v.companyContact.consultantOther.$touch},model:{value:(_vm.companyContact.consultantOther),callback:function ($$v) {_vm.$set(_vm.companyContact, "consultantOther", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyContact.consultantOther"}}),(
                      !_vm.$v.companyContact.consultantOther.required &&
                      _vm.$v.companyContact.consultantOther.dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" Other Consultant is required! ")]):(!_vm.$v.companyContact.consultantOther.minLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.consultantOther.$error &&
                      _vm.$v.companyContact.consultantOther.dirty}},[_vm._v(" Minimum Characters Allowed 2! ")]):(!_vm.$v.companyContact.consultantOther.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyContact.consultantOther.$error &&
                      _vm.$v.companyContact.consultantOther.dirty}},[_vm._v(" Maximum Characters Allowed 300! ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Notes:","label-for":"nested-notes"}},[_c('b-form-textarea',{staticClass:"col-sm-8",attrs:{"id":"nested-notes","rows":"3"},model:{value:(_vm.companyContact.notes),callback:function ($$v) {_vm.$set(_vm.companyContact, "notes", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyContact.notes"}})],1)],1)],1)],1)],1)],1)],1),_c('div',[_c('CompanyContactPhone',{attrs:{"contactID":this.contactID,"companyContactdetail":this.companyContact,"from":this.from,"validation":_vm.$v.$invalid},on:{"onContactIdChange":_vm.onContactIdChange}})],1),_c('b-row',[_c('div',{staticClass:"loginbtnbg"},[_c('api-button-overlay',{attrs:{"processing":_vm.processing}},[_c('b-button',{staticClass:"login-blue",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.saveCompanyContact()}}},[_c('span',{staticClass:"label"},[_vm._v("Save")])])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }