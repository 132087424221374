<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import { instance } from "../../src/request/app-axios.js";

export default {
  data() {
    return {
      statusCode: null,
      processing: false,
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "setModalDangerMessage",
      "setModalAlertMessage",
      "setModalInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "changeCompanyContact",
      "changeApplicationAdditionalContacts",
    ]),
    //Add Record
    async addApplicationCompanyContact(applicationcompanyID, contact) {
      this.processing = true;
      return await instance
        .post(`${WebAPIUrl}/api/applicationcompany-contact/${applicationcompanyID}`, contact)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage(`Successfully Added ${contact.firstName} ${contact.lastName}`);
          this.processing = false;
          return result.data;
        })
        .catch((error) => {
          this.statusCode = error.response.status;
          this.setModalDangerMessage(error.response.data);
          this.processing = false;
        });
    },
    //Delete Record
    async deleteApplicationCompanyContact(applicationcompanyID, contactID) {
      await axios
        .delete(`${WebAPIUrl}/api/applicationcompany-contact/${contactID}/${applicationcompanyID}`)
        .then((result) => {
          this.statusCode = result.status;
          this.appendInfoMessage(result.data);
        })
        .catch((error) => {
          this.appendDangerMessage(error);
        });
    },
    //Get Application Company Contacts
    async getApplicationCompanyContactsTable(applicationcompanyID) {
      await axios
        .get(`${WebAPIUrl}/api/applicationcompany-contact/table/${applicationcompanyID}`)
        .then((result) => {
          const { data = [] } = result.data;
          this.changeCompanyContact(data);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },

    //Get Application Company Additional Contacts
    async getApplicationCompanyAdditionalContacts(companyID, applicationcompanyID) {
      await axios
        .get(`${WebAPIUrl}/api/applicationcompany-contact/${companyID}/${applicationcompanyID}`)
        .then((result) => {
          const { data = [] } = result;
          this.changeApplicationAdditionalContacts(data);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },

    //Add additional contact in application
    async addApplicationCompanyNewContact(companyID, applicationcompanyID, contactID) {
      this.processing = true;
      return await axios
        .post(
          `${WebAPIUrl}/api/applicationcompany-contact/${applicationcompanyID}/${companyID}/${contactID}`
        )
        .then((result) => {
          this.setInfoMessage(result.data);
          this.statusCode = result.status;
          this.processing = false;
          return result.data;
        })
        .catch((error) => {
          this.statusCode = error.response.status;
          this.setDangerMessage(error.response.data.title);
          this.processing = false;
        });
    },

    //Get Application Submitted Company Contacts
    async getApplicationSubmitCompanyContactsTable(applicationcompanyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-contact/by-applicationCompanyID/${applicationcompanyID}`)
        .then((result) => {
          const { data = [] } = result;
          this.changeCompanyContact(data);
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
  },
};
</script>
