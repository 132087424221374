<template>
  <company-contacts
    title="Contacts"
    :tableColumns="tableColumns"
    addNewRecordTitle="Add New Contact"
    editRecordTitle="Edit Contact for - "
    deleteRecordTitle="Delete Contact"
    showCompanyId="true"
    from="manage-contacts"
  />
</template>
<script>
import CompanyContacts from "../../../components/App/Company/CompanyContacts.component.vue";

export default {
  props: [],
  data() {
    return {
      tableColumns: [
        { cell: "myTemplate", title: "Actions", width: "90" },
        {
          field: "companyID",
          title: "Co.ID",
          width: "70",
        },
        {
          field: "contactID",
          title: "Contact ID",
          width: "95",
        },
        {
          field: "firstName",
          title: "First Name",
          width: "105",
        },
        {
          field: "lastName",
          title: "Last Name",
          width: "105",
        },
        {
          field: "contactTypeDescriptions",
          title: "Contact Type",
          cell: "contactTypeDescriptionsTemplete",
          width: "auto",
        },
        // {
        //   field: "phoneNumbers",
        //   title: "Phone Numbers",
        //   cell: "phoneNumbersTemplate",
        //   width: "150px",
        // },
        // {
        //   field: "emailAddress",
        //   title: "Email Address",
        //   width: "150px",
        // },
        // {
        //   field: "notes",
        //   title: "Notes",
        //   cell: "notesTemplate",
        //   width: "80px",
        // },
      ],
    };
  },
  components: {
    CompanyContacts,
  },
};
</script>
