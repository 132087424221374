<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
  >
    <div class="modal-height-adjust">
      <messages />
      <b-row>
        <b-col>
          <b-row class="formrow" v-if="showCompanyId">
            <b-col lg="12">
              <b-form-group label="Company">
                <b-form-select
                  plain
                  v-model="companyContact.companyID"
                  :state="
                    $v.companyContact.companyID.$dirty ? !$v.companyContact.companyID.$error : null
                  "
                  :options="branchLocations"
                  value-field="companyID"
                  text-field="companyLegalName"
                  @input="$v.companyContact.companyID.$touch"
                ></b-form-select>
                <div
                  class="text-danger"
                  v-if="!$v.companyContact.companyID.required && $v.companyContact.companyID.dirty"
                >
                  Company is required!
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card title="Contact Info" icon>
            <b-form>
              <!-- Salutation -->
              <b-row class="formrow">
                <b-col lg="6">
                  <b-form-group label="Salutation:" label-for="nested-salutation">
                    <b-form-input
                      id="nested-salutation"
                      class="col-sm-10"
                      v-model.trim="$v.companyContact.salutation.$model"
                      :state="
                        $v.companyContact.salutation.$dirty
                          ? !$v.companyContact.salutation.$error
                          : null
                      "
                      placeholder="Salutation"
                    >
                    </b-form-input>
                    <div
                      class="text-danger"
                      :state="!$v.companyContact.salutation.$error"
                      v-if="!$v.companyContact.salutation.maxLength"
                    >
                      Maximum Characters Allowed 4!
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group label="Title:" label-for="nested-title">
                    <b-form-input
                      id="nested-title"
                      class="col-sm-10"
                      v-model.trim="$v.companyContact.title.$model"
                      :state="
                        $v.companyContact.title.$dirty ? !$v.companyContact.title.$error : null
                      "
                      placeholder="Title"
                    ></b-form-input>
                    <div
                      class="text-danger"
                      :state="!$v.companyContact.title.$error"
                      v-if="!$v.companyContact.title.maxLength"
                    >
                      Maximum Characters Allowed 50!
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- End of Salutation -->

              <!--First Name-->
              <b-row class="formrow">
                <b-col lg="6">
                  <b-form-group label="First Name:" label-for="nested-firstName">
                    <b-form-input
                      id="nested-firstName"
                      class="col-sm-10"
                      v-model.trim="$v.companyContact.firstName.$model"
                      :state="
                        $v.companyContact.firstName.$dirty
                          ? !$v.companyContact.firstName.$error
                          : null
                      "
                      placeholder="First Name"
                    ></b-form-input>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.companyContact.firstName.required && $v.companyContact.firstName.$dirty
                      "
                    >
                      First Name is required!
                    </div>
                    <div
                      class="text-danger"
                      :state="!$v.companyContact.firstName.$error"
                      v-else-if="!$v.companyContact.firstName.maxLength"
                    >
                      Maximum Characters Allowed 50!
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="d-flex">
                  <b-form-group label="Last Name:" label-for="nested-lastName">
                    <b-form-input
                      id="nested-lastName"
                      class="col-sm-10"
                      v-model.trim="$v.companyContact.lastName.$model"
                      :state="
                        $v.companyContact.lastName.$dirty
                          ? !$v.companyContact.lastName.$error
                          : null
                      "
                      placeholder="Last Name"
                    ></b-form-input>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.companyContact.lastName.required && $v.companyContact.lastName.$dirty
                      "
                    >
                      Last Name is required!
                    </div>
                    <div
                      class="text-danger"
                      :state="!$v.companyContact.lastName.$error"
                      v-else-if="!$v.companyContact.lastName.maxLength"
                    >
                      Maximum Characters Allowed 50!
                    </div>
                  </b-form-group>
                  <b-form-group label="Suffix:" label-for="nested-suffix" class="ms-2">
                    <b-form-input
                      id="nested-suffix"
                      class="col-sm-10"
                      v-model.trim="$v.companyContact.suffix.$model"
                      :state="
                        $v.companyContact.suffix.$dirty ? !$v.companyContact.suffix.$error : null
                      "
                      placeholder="Suffix"
                    ></b-form-input>
                    <div
                      class="text-danger"
                      :state="!$v.companyContact.suffix.$error"
                      v-if="!$v.companyContact.suffix.maxLength"
                    >
                      Maximum Characters Allowed 50!
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--End of First Name-->

              <!--Email-->
              <b-row class="formrow">
                <b-col lg="12">
                  <b-form-group label="Email:" label-for="nested-emailaddress">
                    <b-form-input
                      id="nested-emailaddress"
                      class="col-sm-6"
                      v-model.trim="$v.companyContact.emailAddress.$model"
                      :state="
                        $v.companyContact.emailAddress.$dirty
                          ? !$v.companyContact.emailAddress.$error
                          : null
                      "
                      placeholder="Email"
                    ></b-form-input>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.companyContact.emailAddress.required &&
                        $v.companyContact.emailAddress.$dirty
                      "
                    >
                      Email is required!
                    </div>
                    <div
                      class="text-danger"
                      :state="!$v.companyContact.emailAddress.$error"
                      v-else-if="!$v.companyContact.emailAddress.email"
                    >
                      Enter a valid email address!
                    </div>
                    <div
                      class="text-danger"
                      :state="!$v.companyContact.emailAddress.$error"
                      v-else-if="!$v.companyContact.emailAddress.maxLength"
                    >
                      Maximum Characters Allowed 50!
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--End of Email-->
              <!--Contact Types-->
              <b-row class="formrow">
                <b-col lg="12">
                  <b-form-group label="Contact Type(s):" label-for="nested-contacttypes">
                    <multiselect
                      v-model="companyContact.contactTypes"
                      :options="dynamicContactTypeOptions"
                      group-values="options"
                      group-label="label"
                      track-by="id"
                      label="description"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Select Contact Type(s)..."
                      :class="{ 'multiselect-is-invalid': $v.companyContact.contactTypes.$error }"
                    >
                      <template slot="option" slot-scope="props">
                        <div :title="props.option.tooltip">
                          {{ getOptionLabel(props) }}
                        </div>
                      </template>
                    </multiselect>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.companyContact.contactTypes.required &&
                        $v.companyContact.contactTypes.$dirty
                      "
                    >
                      Contact type is required!
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--End of Contact Types-->
              <!-- Open Other consulting firem -->
              <b-row class="formrow" v-if="showOtherConsultingFirm">
                <b-col lg="12">
                  <b-form-group label="Other Consulting Firm" label-for="nested-contacttypes">
                    <b-form-input
                      type="text"
                      v-model="companyContact.otherConsultingFirm"
                      :state="
                        $v.companyContact.otherConsultingFirm.$dirty
                          ? !$v.companyContact.otherConsultingFirm.$error
                          : null
                      "
                      @input="$v.companyContact.otherConsultingFirm.$touch"
                      placeholder="Other Consulting Firm"
                    />
                    <div
                      class="text-danger"
                      v-if="
                        !$v.companyContact.otherConsultingFirm.required &&
                        $v.companyContact.otherConsultingFirm.$dirty
                      "
                    >
                      Other Consulting Firm is required!
                    </div>
                    <div
                      class="text-danger"
                      :state="
                        !$v.companyContact.otherConsultingFirm.$error &&
                        $v.companyContact.otherConsultingFirm.dirty
                      "
                      v-else-if="!$v.companyContact.otherConsultingFirm.maxLength"
                    >
                      Maximum Characters Allowed 300!
                    </div>
                  </b-form-group>
                </b-col></b-row
              >
              <!-- End Other consulting firm -->
              <!-- Optional affiliate info -->
              <b-row
                class="formrow"
                v-show="
                  companyContact &&
                  companyContact.contactTypes &&
                  companyContact.contactTypes.find((ct) => ct.id === 15) !== undefined
                "
              >
                <b-col lg="12">
                  <b-form-group label="Consulting Firm Name">
                    <b-form-select
                      plain
                      v-model="companyContact.affiliateID"
                      :state="
                        $v.companyContact.affiliateID.$dirty
                          ? !$v.companyContact.affiliateID.$error
                          : null
                      "
                      :options="consultants"
                      @input="$v.companyContact.affiliateID.$touch"
                    ></b-form-select>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.companyContact.affiliateID.required &&
                        $v.companyContact.affiliateID.dirty
                      "
                    >
                      Consultant is required!
                    </div>
                  </b-form-group>

                  <b-form-group label="Other" v-show="companyContact.affiliateID === -1">
                    <b-form-input
                      type="text"
                      v-model.trim="companyContact.consultantOther"
                      @input="$v.companyContact.consultantOther.$touch"
                      :state="
                        $v.companyContact.consultantOther.dirty
                          ? !$v.companyContact.consultantOther.$error
                          : null
                      "
                    />
                    <div
                      class="text-danger"
                      v-if="
                        !$v.companyContact.consultantOther.required &&
                        $v.companyContact.consultantOther.dirty
                      "
                    >
                      Other Consultant is required!
                    </div>
                    <div
                      class="text-danger"
                      :state="
                        !$v.companyContact.consultantOther.$error &&
                        $v.companyContact.consultantOther.dirty
                      "
                      v-else-if="!$v.companyContact.consultantOther.minLength"
                    >
                      Minimum Characters Allowed 2!
                    </div>
                    <div
                      class="text-danger"
                      :state="
                        !$v.companyContact.consultantOther.$error &&
                        $v.companyContact.consultantOther.dirty
                      "
                      v-else-if="!$v.companyContact.consultantOther.maxLength"
                    >
                      Maximum Characters Allowed 300!
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--End optional affiliate info-->
              <!--Notes-->
              <b-row class="formrow">
                <b-col lg="12">
                  <b-form-group label="Notes:" label-for="nested-notes">
                    <b-form-textarea
                      id="nested-notes"
                      class="col-sm-8"
                      rows="3"
                      v-model.trim="companyContact.notes"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--End of Notes-->
            </b-form>
          </b-card>
        </b-col>
      </b-row>

      <!--Contact Phone-->
      <div>
        <CompanyContactPhone
          :contactID="this.contactID"
          @onContactIdChange="onContactIdChange"
          :companyContactdetail="this.companyContact"
          :from="this.from"
          :validation="$v.$invalid"
        ></CompanyContactPhone>
      </div>

      <b-row>
        <div class="loginbtnbg">
          <api-button-overlay :processing="processing">
            <b-button class="login-blue" @click="saveCompanyContact()" :disabled="$v.$invalid">
              <span class="label">Save</span>
            </b-button>
          </api-button-overlay>
        </div>
      </b-row>
      <!--End Contact Phone-->
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
.modal-height-adjust {
  height: 560px;
  max-height: 560px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
}
</style>

<script>
import CompanyContactPhone from "../../views/app/Company/CompanyContactPhone.vue";
import CompanyContactMixin from "../../mixins/CompanyContactMixin.vue";
import CompanyMixin from "../../mixins/CompanyMixin.vue";
import { validationMixin } from "vuelidate";
import { mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import ModalMessages from "../../containers/navs/ModalMessages.vue";
import PhoneMixin from "../../mixins/PhoneMixin.vue";
import AffiliateMixin from "../../mixins/AffiliateMixin.vue";
import ApplicationCompanyContactMixin from "../../mixins/ApplicationCompanyContactMixin.vue";

const {
  required,
  requiredIf,
  // alpha,
  maxLength,
  minLength,
  email,
} = require("vuelidate/lib/validators");
import { bus } from "../../main";

export default {
  name: "companyAddressModal",
  props: ["applicationCompanyID", "from"],
  components: {
    messages: ModalMessages,
    multiselect: Multiselect,
    CompanyContactPhone,
  },
  mixins: [
    CompanyContactMixin,
    PhoneMixin,
    validationMixin,
    AffiliateMixin,
    CompanyMixin,
    ApplicationCompanyContactMixin,
  ],
  validations: {
    companyContact: {
      companyID: {
        required: requiredIf(function () {
          return this.showCompanyId;
        }),
      },
      salutation: {
        maxLength: maxLength(4),
      },
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      suffix: {
        maxLength: maxLength(50),
      },
      emailAddress: {
        required,
        email,
        maxLength: maxLength(50),
      },
      title: {
        maxLength: maxLength(50),
      },
      contactTypes: {
        required,
      },
      affiliateID: {
        required: requiredIf(function () {
          return (
            this.companyContact &&
            this.companyContact.contactTypes &&
            this.companyContact.contactTypes.find((ct) => ct.id === 15) !== undefined
          );
        }),
      },
      consultantOther: {
        required: requiredIf(function () {
          if (this.companyContact.affiliateID === null) {
            return false;
          }

          return this.companyContact.affiliateID === -1;
        }),
        minLength: minLength(2),
        maxLength: maxLength(300),
      },
      otherConsultingFirm: {
        required: requiredIf(function () {
          return this.showOtherConsultingFirm;
        }),
        maxLength: maxLength(300),
      },
    },
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    contactID: 0,
    showCompanyId: false,
    dynamicContactTypeOptions: [],
  }),

  async created() {
    //Selected Programs where Company Info
    let programIDs = "";
    bus.$on("selectedPrograms", async (data) => {
      programIDs = data.map(({ programID }) => programID).join(",");
      const params = {
        programIDs,
      };
      await this.getContactTypeOptions(params);
      bus.$emit("contactTypeOptions", this.contactTypeOptions);
    });
    await this.getContactTypeOptions();
    await this.getAllConsultants();
    this.mapConsultants();
  },
  beforeDestroy() {
    bus.$off("selectedPrograms");
  },
  computed: {
    showOtherConsultingFirm() {
      return (
        this.companyContact.contactTypes &&
        this.companyContact.contactTypes.length &&
        this.companyContact.contactTypes.find(({ id }) => id === 4)
      );
    },
  },
  methods: {
    ...mapMutations(["setModalDangerMessage", "setModalInfoMessage"]),
    async show(opts = {}) {
      this.title = opts.title;

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }

      if (opts.showCompanyId) {
        this.showCompanyId = opts.showCompanyId;
        const { companyID = "" } = JSON.parse(localStorage.getItem("currentCompany"));
        await this.getBranchLocations(companyID);
      }
      this.initNewContact(opts.companyID);
      if (opts.contactID) {
        this.contactID = opts.contactID;
        await this.getCompanyContactByContactID(this.contactID);
      }

      this.isVisible = true;
      const { assignedContactTypes = [] } = opts;
      //Filter already assigned contact type options
      this.dynamicContactTypeOptions = assignedContactTypes.length
        ? this.contactTypeOptions.map((rec) => {
            const filOptions = rec.options.filter(({ description }) => {
              if (description === "Organizational Leader" || description === "Billing Contact") {
                return !assignedContactTypes.includes(description);
              }
              return true;
            });
            return {
              ...rec,
              options: filOptions,
            };
          })
        : this.contactTypeOptions;
    },
    close() {
      if (this.exitFunction) {
        this.exitFunction();
      }
      this.dynamicContactTypeOptions = this.contactTypeOptions; //reset contact type options
      this.contactID = 0;
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
    async saveCompanyContact() {
      const contactDetails = {
        ...this.companyContact,
        otherConsultingFirm: this.showOtherConsultingFirm
          ? this.companyContact.otherConsultingFirm
          : null,
      };
      if (this.contactID) {
        await this.updateCompanyContact({
          ...contactDetails,
          contactID: this.contactID,
        });
        this.close();
      } else {
        let id;
        if (this.from === "application-company-contacts") {
          id = await this.addApplicationCompanyContact(this.applicationCompanyID, contactDetails);
        } else {
          id = await this.addCompanyContact(contactDetails);
        }
        if (this.statusCode === 200) {
          // If the contact was successfully added, user stays on form, but future saves are treated as updates now
          this.contactID = id;
          await this.getCompanyContactByContactID(this.contactID);
          this.close();
        }
      }
    },
    initNewContact(companyID) {
      this.companyContact = {
        companyID: companyID,
        firstName: "",
        lastName: "",
        suffix: "",
        salutation: "",
        title: "",
        emailAddress: "",
        notes: null,
        createdByID: 0,
        active: true,
        phoneNumbers: "",
        affiliateID: null,
        consultantOther: null,
        contactTypes: [],
        otherConsultingFirm: null,
      };
    },
    mapConsultants() {
      this.consultants = this.consultants.map((c) => {
        return {
          text: c.companyName,
          value: c.affiliateID,
        };
      });

      // Pierson 9/15/21 - I added the push in here instead of in the API
      // so that it would keep the API agnostic and not mess up other GetAll calls
      this.consultants.push({
        value: -1,
        text: "Other",
      });
    },
    getOptionLabel(props) {
      if (props.option.description === undefined) {
        return props.option.$groupLabel;
      }

      return props.option.description;
    },
    onContactIdChange(contactID) {
      if (contactID) {
        this.contactID = contactID;
      }
    },
  },
};
</script>
<style>
.tooltip-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

#address-type-label {
  margin-bottom: 0;
}

/* Google Auto complete suggestion adjust  */
.pac-container {
  z-index: 1050;
}
</style>
