<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";

export default {
  data() {
    return {
      phoneTypes: [],
      phoneMask: "(###) ###-####",
      extMask: "######",
    };
  },

  methods: {
    async getPhoneTypes() {
      await axios
        .get(`${WebAPIUrl}/api/company-phone/phone-type`)
        .then((result) => {
          this.phoneTypes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    // remove phone mask
    removePhoneMask(maskNumber) {
      const number = maskNumber.replace(/[^0-9]/g, "");
      return number;
    },
  },
};
</script>
